import React, { useEffect } from "react";
import { useFrame, useLoader } from '@react-three/fiber'
import { Mesh } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useScroll } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";


export function Body() {
    // const materials = useLoader(MTLLoader, process.env.PUBLIC_URL + "models/05_head_trunk2_final_v2_realign.mtl")
    // const colorMap = useLoader(TextureLoader, process.env.PUBLIC_URL + "models/textures/05_head_thorax_2_final_v2_realign.png")
    // const obj = useLoader(
    //     OBJLoader,
    //     process.env.PUBLIC_URL + "models/05_head_trunk2_final_v2_realign.obj",
    //     loader => {
    //         materials.preload()
    //         loader.setMaterials(materials)
    //       })
    // obj.position.set(0, 15, 0);
    // obj.rotation.x = Math.PI / 2;

//     return <primitive object={obj} />
// }
    const scroll = useScroll()

    const gltf = useLoader(
        GLTFLoader, 
        process.env.PUBLIC_URL + "models/body_torso4.glb", loader => {
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
            dracoLoader.setDecoderConfig({type: 'js'});
            loader.setDRACOLoader(dracoLoader);
           });


    useFrame((state, delta) => {
        const offset = 2 - scroll.offset
        state.camera.position.set(Math.sin(offset) * 1, Math.atan(offset * Math.PI * 2) * 4, Math.cos((offset * Math.PI) / 3) * -11)
        // state.camera.lookAt(0, 0, 0)    
      })

    useEffect(() => {
        gltf.scene.scale.set(1, 1, 1);
        
        gltf.scene.traverse((object) => {
            if (object instanceof Mesh) {
                object.castShadow = true;
                object.receiveShadow = true;
                object.material.envMapIntensity = 20;
            }
        });
    }, [gltf]);

    return <primitive object={gltf.scene} />;
}
