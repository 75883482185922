import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import './App.css';
import { OrbitControls, PerspectiveCamera, Scroll, ScrollControls } from '@react-three/drei';
import { Body } from './Body';
import { Ground } from './Ground';

function BodyShow() {
  return (
    <>
      <OrbitControls 
        target={[0, 2.5, 0]} 
        maxPolarAngle={2.2} 
        enableZoom={false}
        enableRotate={false}
      />

      <PerspectiveCamera 
        makeDefault 
        fov={90} 
        position={[0.5, 4, 5]} 
      />

      <color args={[0, 0, 0]} attach="background" />

      {/* <ambientLight intensity={1} />
      <spotLight intensity={0.5} angle={0.1} penumbra={1} castShadow /> */}

      <Body />
      <spotLight
        color={[0.5, 0.5, 0.5]} //[1, 0.25, 0.7]
        intensity={2}
        angle={0.6}
        penumbra={0.5}
        position={[5, 7, 3]}
        castShadow
        shadow-bias={-0.0001}
      />
      <spotLight
        color={[0.5, 0.5, 0.5]} //[0.14,  0.5, 1]
        intensity={2}
        angle={0.6}
        penumbra={0.5}
        position={[-5, 7, 3]}
        castShadow
        shadow-bias={-0.0001}
      />

      <spotLight
        color={[0.5, 0.5, 0.5]} //[0.14,  0.5, 1]
        intensity={2}
        angle={0.6}
        penumbra={0.5}
        position={[0, 7, -3]}
        castShadow
        shadow-bias={-0.0001}
      />

      <Ground />
    </>
  );
}

function App() {
  return (
    <Suspense fallback={null}>
      <Canvas>
      <ScrollControls pages={5} damping={0.25}>
        <BodyShow />
        <Scroll html style={{ width: '100%', color: 'white' }}>
        <h1 style={{ position: 'absolute', top: `20vh`, right: '12vw', fontSize: '25em', transform: `translate3d(0,-100%,0)` }}>TEST</h1>
        <h1 style={{ position: 'absolute', top: '190vh', left: '10vw', right: '55vw' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit ut. Ante metus dictum at tempor commodo. Purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Nibh venenatis cras sed felis eget velit aliquet. Aliquam sem et tortor consequat id. Mi eget mauris pharetra et ultrices neque ornare aenean. Fermentum et sollicitudin ac orci phasellus egestas. Nisl vel pretium lectus quam id. Aliquet lectus proin nibh nisl condimentum id. Dignissim diam quis enim lobortis scelerisque fermentum dui faucibus. Eget velit aliquet sagittis id. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Augue mauris augue neque gravida in. Est ullamcorper eget nulla facilisi etiam. Pulvinar etiam non quam lacus. Nulla facilisi cras fermentum odio eu feugiat.</h1>
        <h1 style={{ position: 'absolute', top: '400vh', right: '10vw', left: '55vw ' }}>Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Sed sed risus pretium quam vulputate. Id donec ultrices tincidunt arcu. Odio ut enim blandit volutpat maecenas volutpat blandit aliquam etiam. Fringilla est ullamcorper eget nulla. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Habitant morbi tristique senectus et netus et malesuada. Et malesuada fames ac turpis egestas integer. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin. Sem et tortor consequat id porta. Ac orci phasellus egestas tellus rutrum tellus pellentesque. Tincidunt vitae semper quis lectus nulla at. Ornare quam viverra orci sagittis eu volutpat odio.</h1>
      </Scroll>
      </ScrollControls>
      </Canvas>
    </Suspense>
  );
}

export default App;
